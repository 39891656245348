<template>
  <div>
<!--    <div @click="edit">-->
      <img src="../assets/image/jia14.png" style="vertical-align:middle;margin-right:4px" @click="edit" class="img-class"><span @click="edit" class="img-text">修改信息</span>
<!--    </div>-->
    <div class="dialog_edit">
      <el-dialog :visible.sync="addprojectdialog" top="5vh">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改信息</span>
        </div>
        <div class="body">
          <div class="body_left">
            <el-form label-width="160px">
              <el-form-item :label="nameType + '类型:'">
                <el-select placeholder="请选择" v-model="editForm.TopicType">
                  <el-option :label="item.TypeName" :value="item.Id" v-for="(item,index) in ProjectTypelist" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="nameType + '方式:'">
                <el-select placeholder="请选择" v-model="editForm.TopicMode">
                  <el-option :label="item.Name" :value="item.Id" v-for="(item,index) in ProjectModelist" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主题方向:">
                <el-input v-model="editForm.TopicDirection"></el-input>
              </el-form-item>
              <el-form-item :label="nameType + '名字:'">
                <el-input v-model="editForm.TopicName"></el-input>
              </el-form-item>
              <el-form-item :label="nameType + '编号:'">
                <el-input v-model="editForm.TopicCode"></el-input>
              </el-form-item>
              <el-form-item label="项目牵头单位:" v-show="TopicClass == 1">
                <el-input v-model="editForm.HospitalName" :disabled="disabled"></el-input>
              </el-form-item>
              <el-form-item :label="nameType + '承担单位:'" v-show="TopicClass != 1">
                <el-input v-model="editForm.HospitalName" :disabled="disabled" v-show="showflag"></el-input>
                 <el-autocomplete v-show="!showflag"
                  class="inline-input"
                  v-model="editForm.HospitalName"
                  value-key="HospitalName"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="管理员:">
                <el-input  v-model="editForm.UserName" class="input1" :disabled="disabled" v-show="showflag"></el-input>
                <span class="fromHeader" v-show="!showflag">
               <el-select v-model="editForm.UserName" placeholder="请选择" filterable  @change="handleSelect1">
                    <el-option class="input2"
                      :label="item.UserName"
                      :value="item.Id"
                      v-for="(item, index) in allpersonnellist"
                      :key="index"
                    ></el-option>
                  </el-select>
                </span>
                <span class="span1">负责人:</span>
                <el-input  v-model="editForm.Principal" class="input1"></el-input>
              </el-form-item>
              <el-form-item label="课题序号:"  v-show="TopicClass==2">
                <el-input v-model="editForm.SerialNumber"></el-input>
              </el-form-item>
              <el-form-item label="子课题序号:"  v-show="TopicClass==3">
                <el-input v-model="editForm.SerialNumber"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="body_right">
            <el-form label-width="200px">
              <el-form-item :label="nameType + '经费:'">
                <el-input v-model="editForm.Funds"></el-input>
                <span>万元</span>
              </el-form-item>
              <el-form-item label="起止时间:">
                <div class="block">
                  <el-date-picker
                    v-model="editForm.StartDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                  <span>至</span>
                  <el-date-picker
                    v-model="editForm.EndDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item label="研究内容:">
                <el-input v-model="editForm.Remarks" type="textarea"></el-input>
              </el-form-item>
              <el-form-item label="考核指标:">
                <el-input v-model="editForm.Assess" type="textarea"></el-input>
              </el-form-item>
              <el-form-item label="附件:">
                <div style="max-height: 70px;overflow: auto">
                <div v-for="(item,index) in tableDataSee" :key="index" style="position: relative;width: 400px;line-height: 18px;">
                  <el-tooltip placement="top">
                    <div slot="content">{{item.FileName}}.{{item.Suffix}}</div>
                    <span style="width:300px;display: inline-block;overflow:hidden;text-overflow: ellipsis;white-space: nowrap"><i class="el-icon-document"></i>{{item.FileName}}.{{item.Suffix}}</span>
                  </el-tooltip>
                  <p class="del-icon" @click="deletefile(item.Id)" style="position:absolute;top:0;right: 0 ">x</p>
                </div>
                </div>
                <el-upload
                  class="upload-demo"
                  ref="newupload"
                  accept=".xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.xlsx,"
                  multiple
                  action="#"
                  :file-list="fileList"
                  :auto-upload="false"
                  :on-change="oncheng"
                  :before-remove="handleRemove"
                >
                  <el-button size="medium" type="primary" :disabled="jurisdictionshow">点击上传</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item label="附件备注:">
                <el-input v-model="editForm.FileRemarks" type="textarea"></el-input>
              </el-form-item>
              <el-form-item label="是否审核标注:">
                <el-radio-group v-model="editForm.IsCheck">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否需要签署保密协议:" v-show="this.TopicClass==2||this.TopicClass==3?false:true">
                    <div style="display: flex;">
                       <el-radio-group v-model="editForm.Confidentiality">
                        <el-radio :label="1" style="margin-top: 12px;">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                      <el-upload
                         name="file1"
                        :headers="headers"
                        class="upload-demo"
                        accept=".jpg,.png,.svg,.pdf"
                        multiple
                        :action="url"
                        :file-list="secrecylist"
                        :on-change="secrecyoncheng"
                        :data="{data:JSON.stringify({TopicId:editProjectId})}"
                      >
                        <el-button :disabled="jurisdictionshow" size="medium" type="primary" style="margin-left: 10px;" v-show="editForm.Confidentiality==1?true:false">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip" v-show="editForm.Confidentiality==1?true:false">只允许上传PDF文件或图片</div>
                      </el-upload>  
                       <!-- <el-upload
                        class="upload-demo"
                        accept=".jpg,.png,.svg,.pdf"
                        multiple
                        action="#"
                        :file-list="secrecylist"
                        :auto-upload="false"
                        :on-change="secrecyoncheng"
                      >
                        <el-button size="medium" type="primary" style="margin-left: 10px;" v-show="editForm.Confidentiality==1?true:false">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip" v-show="editForm.Confidentiality==1?true:false">只允许上传PDF文件或图片</div>
                      </el-upload>   -->
                    </div>
                    <div style="margin-left: 110px;" v-for="(item,index) in filearr" :key="index" v-show="editForm.Confidentiality==1?true:false">
                        <span>{{item.FileName}}.{{item.Suffix}}</span><span style="margin-left: 30px;" @click="Deletefile(item.Id)">x</span>
                    </div>             
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="btn">
          <el-button @click="addprojectdialog=false">取消</el-button>
          <el-button @click="editproject" v-show="!jurisdictionshow">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="FinanceVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFilesDialog"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFilesDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
     <div class="FinanceVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFilesDialog1"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFilesDialog1 = false">取消</button>
          <button @click="submitDel1">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/api/url";
import research from "@/api/research";
import reseacrh from "@/api/research";
import apis from "@/api/Grantsandmilestones"
export default {
  name: "editProject",
  data() {
    return {
      // rashow:false,
      secrecylist:[],
      Confidentiality:0,
      DataGuid: "",
      delFilesDialog: false, // 删除文件
      tableDataSee: [], //编辑查看到的数据列表
      editForm: {

      },
      Remarks: "",
      ProjectTypelist: [],
      ProjectModelist: [],
      dialogVisible:false,
      show:true,
      fileList: [],
      timelist: [], //时间数组
      addprojectdialog: false, //打开项目
      kid:0,//储存项目id
      disabled:true,
      allpersonnellist:[],
      // TopicPi:"",
      showflag:true,
      topicpi:"",
      total:0,
      filearr:[],
      secrecyfilearr:[],
      delFilesDialog1:false,
      url:window.global_config.BASE_URL+"TopicInfo/UploadTopicConfidentiality",
      jurisdictionshow:false,
    }
  },
  props: ["editProjectId","TopicClass"],
  computed: {
    nameType() {
      var projectName = ""
      if (this.TopicClass == 1) {
       projectName = "项目"
      }
      if (this.TopicClass == 2) {
        projectName = "课题"
      }
      if (this.TopicClass == 3) {
        projectName = "子课题"
      }
      return projectName
    },
     headers() {
      return {
        accessToken: sessionStorage.getItem("Token"),
      };
    },
    // datalist(){
    //  return data:{TopicId:this.editProjectId}
    // }
  },
  methods: {
    Deletefile(Id){
      if(this.jurisdictionshow==true){
            this.$message({
                    showClose: true,
                    message: "您没有操作权限!",
                    type: 'warning'
           });
      }else{
        this.delFileId = Id
        this.delFilesDialog1 = true
      }
    },
    submitDel1(){
      reseacrh.alldelet({Id:this.delFileId}).then(res=>{
        if(res.data.Status==1){
          apis.secrecyfileData(this.editProjectId).then(res=>{
              console.log(res,"@@@@@@@@@@@@@");
              if(res.data.Status==1){
                this.filearr=res.data.Entity
                this.delFilesDialog1=false
              }else{
                alert(res.data.Message)
              }

        })
          this.$message.success(res.data.Message)
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    secrecyoncheng(file, fileList) {
      this.secrecyfilearr = fileList;
      console.log(file, fileList);
    },
    // radiochange(){
    //   if(this.Confidentiality==1){
    //     this.rashow=true
    //   }else{
    //     this.rashow=false
    //   }
    //   console.log(this.Confidentiality);
    // },
    handleSelect1(item){
      console.log(item,"222222");
       this.topicpi=item
    },
        querySearch(queryString, cb){//查询出医院
          console.log(queryString, cb);
          let parame={
              key:queryString
          }
          api.hospitalData(parame).then(res=>{
               console.log(res);
               if(res.data.Status==1){
                 cb(res.data.Entity)
               }else{
                 alert(res.data.Message)
               }
          })
    },
        handleSelect(item) {//查询出人员

        api.hospitalIdsData(item.Id).then(res=>{
            if(res.data.Status==1){
              this.allpersonnellist=res.data.Entity
            }else{
              alert(res.data.Message)
            }
          console.log(res,"人员");
        })
    },
    submitDel() { // 提交删除单个文件
      reseacrh.alldelet({Id:this.delFileId}).then(res=>{
        if(res.data.Status==1){
          reseacrh.preFinancFile(this.DataGuid).then(res=>{
            if (res.data.Status==1) {
              this.tableDataSee = res.data.ExtraData
              this.delFilesDialog = false
            }
          })
          this.$message.success(res.data.Message)
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    deletefile(id){//删除文件
      this.delFileId = id
      this.delFilesDialog = true
    },
    edit() {
       let parames={
          menu:"project",
          topicId:this.editProjectId
       }
       apis.judgeData(parames).then(res=>{
        if(res.data.Status==1){
          this.fileList = []
          this.secrecylist=[]
          this.projectInfo()
          this.ProjectTypeSelect()
          this.ProjectModeSelect()
          this.addprojectdialog = true
          let parame={
              menu:"projectupdate",
              topicId:this.editProjectId
          }
          apis.judgeData(parame).then(res=>{
            if(res.data.Status!=1){
              this.jurisdictionshow=true
            }else{
              this.jurisdictionshow=false
            }
          })
        }else{
            this.$message({
                 showClose: true,
                 message: res.data.Message,
                 type: 'warning'
            });
        }
      })
    },
    projectInfo() {
      apis.secrecyfileData(this.editProjectId).then(res=>{
              console.log(res,"@@@@@@@@@@@@@");
              if(res.data.Status==1){
                this.filearr=res.data.Entity
              }else{
                alert(res.data.Message)
              }

      })
      console.log(this.editProjectId,"333333");
      api.singletopicData(this.editProjectId).then( res=> {
        console.log(res,"项目信息1");
        if(res.data.ExtraData.Pid!=0){
          console.log(this.disabled);
         api.singletopicData(res.data.ExtraData.ProjectId).then(res=>{
             if(res.data.Status==1){
               console.log(res.data.ExtraData.TopicPi);
                 if(res.data.ExtraData.TopicPi==sessionStorage.getItem("Id")){
                   this.showflag=false
                    this.disabled=false
                    console.log(this.disabled);
                  }
             }
        })
        }
        this.DataGuid = res.data.ExtraData.DataGuid
        reseacrh.preFinancFile(this.DataGuid).then(res=>{
          console.log(res,"项目信息2");
          if (res.data.Status==1) {
            this.tableDataSee = res.data.ExtraData
            if (res.data.ExtraData.length > 0) {
              var findItem = res.data.ExtraData.find(item => item.Remarks != "")
              this.Remarks = findItem.Remarks
            }
          }
        })
        this.topicpi=res.data.ExtraData.TopicPi
        this.editForm = res.data.ExtraData
        this.editForm.TopicPi=res.data.ExtraData.UserName
       console.log(this.topicpi);
        // console.log(this.editForm,"项目信息");
        
      })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    oncheng(file, fileList) {
      this.fileList = fileList;
    },
    ProjectTypeSelect() {
      //项目类型
      api.ProjectTypeData().then((res) => {
        if (res.data.Status == 1) {
          this.ProjectTypelist = res.data.Entity;
        } else {
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    ProjectModeSelect() {
      //项目方式
      api.ProjectModeData("TopicMode").then((res) => {
        if (res.data.Status == 1) {
          this.ProjectModelist = res.data.Entity;
        } else {
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    editproject() {
      console.log(this.editForm.Confidentiality,this.secrecyfilearr.length);
      console.log(this.editForm.UserName);
       
      if(this.editForm.Principal==""){
        this.$message.error("项目负责人不能为空")
        return false
      }else{
        console.log(this.topicpi);
        this.editForm.TopicPi=this.topicpi
        console.log( this.editForm);
        api.uptopData(this.editForm).then((res) => {
          if (res.data.Status == 1) {
            this.$message({
              message: res.data.Message,
              type: "success",
            });
            // if(this.editForm.Confidentiality == 1&&this.secrecyfilearr.length!=0){//上传保密协议
            //           let parames={
            //             TopicId:res.data.NewID.Id
            //           }
            //             let fds = new FormData();
            //             this.secrecyfilearr.forEach(file => {
            //             fds.append('file1',file.raw)
            //             })
            //           fds.append("data", JSON.stringify(parames));
            //           apis.secrecyData(fds).then(res=>{
            //                if(res.data.Status!=1){
            //                     alert(res.data.Message)
            //                }
            //               console.log(res,"$$$$$$$$$$$$$$$$$");
            //             })
            // }
            if (this.fileList.length>0) {
              let fd = new FormData();
              this.fileList.forEach(file => {
                fd.append('file',file.raw)
              })
              var time = new Date()
              var obj = {
                TopicId: this.editProjectId,
                DataGuid: this.editForm.DataGuid,
                PDataGuid: "",
                SysFileName: "",
                FileName: "",
                Suffix: "",
                CheckFlag: 0,
                DateTime: time,
                UserId: 0,
                Type: 3,
                Remarks: this.Remarks,
              }
              fd.append("data", JSON.stringify(obj))
              research.editProjectFile(fd).then(res => {
                console.log(res)
              })
            }
            this.$emit('project-edit', true )  //刷新列表
            this.addprojectdialog=false
          } else {
            this.$message.error(res.data.Message);
          }
          console.log(res,"dddddddddddddddddddddddddddddddddd");
        });
        return true;
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../styles/mixin.scss";
.dialog_edit {
/deep/.el-dialog {
  width: 1400px;
  border-radius: 10px;
.el-dialog__header {
  border-radius: 10px 10px 0px 0px;
  background: #3388ff;
.header-title {
.title-name {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 5px;
}
.title-age {
  vertical-align: top;
  display: inline-block;
@include add-size($font_size_16);
  color: #ffffff;
}
}
.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
}
.body {
  height: 520px;
.body_left {
  float: left;
.el-form {
.el-form-item {
.el-form-item__label {
@include add-size($font_size_16);
  color: #666666;
}
.el-input {
@include add-size($font_size_16);
  width: 390px;
}
.fromHeader .el-select .el-input {
  width: 160px;
}
.input1{
  width: 150px;
  margin-right: 10px;
@include add-size($font_size_16);
}
.span1{
  margin-right: 10px;
@include add-size($font_size_16);
}
}
}
}
.body_right {
  float: left;
.el-form {
.el-form-item {
.el-form-item__label {
@include add-size($font_size_16);
  color: #666666;
}
.el-input {
@include add-size($font_size_16);
  width: 390px;
}
.el-textarea__inner{
  width: 390px;
@include add-size($font_size_16);
}
span{
@include add-size($font_size_16);
}
.el-icon-circle-plus-outline {
  width: 30px;
  display: inline-block;
  font-size: 30px;
  vertical-align: middle;
}
.pspan {
  display: inline-block;
  width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
.spanname {
@include add-size($font_size_16);
  margin-left: 6px;
}
}
.block {
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 390px;
}
  .el-input {
    @include add-size($font_size_16);
    width: 186px;
  }
}
/deep/.el-radio__label {
@include add-size($font_size_16);
}
}
}
}
}
.btn {
  text-align: center;
button:nth-child(1) {
  width: 180px;
  background: #ffffff;
  border: 1px solid #3388ff;
  height: 50px;
  border-radius: 10px;
  font-size: 24px;
  color: #3388ff;
  margin-right: 20px;
}
button:nth-child(2) {
  width: 180px;
  border: 1px solid #3388ff;
  background: #3388ff;
  height: 50px;
  border-radius: 10px;
  font-size: 24px;
  color: #fff;
}
}
}
}
.FinanceVoucher-dialog /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 4px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.img-class:hover {
  cursor:pointer
}
.img-text:hover {
  cursor: pointer;
}
</style>
